import { navigate } from "gatsby"
import React, { useState } from "react"
import { useAuth } from "../middleware/AuthGuard"
import * as styles from "./signin.module.scss"

const Signin: React.FC = () => {
  const [account, setAccount] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState<string | null>(null)
  const { login } = useAuth()

  const handleSignin = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await login(account, password)
    } catch (err) {
      setError("ログインに失敗しました")
    }
  }

  return (
    <div className={styles.signinContainer}>
      <h2>ログイン</h2>
      <form onSubmit={handleSignin}>
        <div className={styles.formGroup}>
          <label htmlFor="account">アカウント</label>
          <input
            autoCapitalize="off"
            type="text"
            id="account"
            name="account"
            value={account}
            onChange={e => setAccount(e.target.value)}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="password">ログインパスワード</label>
          <input
            autoCapitalize="off"
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p className={styles.error}>{error}</p>}
        <div className={styles.formGroup}>
          <a
            href="https://www.i-tech-corp.co.jp/policy/"
            className={styles.privacyPolicy}
          >
            プライバシーポリシー
          </a>
        </div>
        <button type="submit" className={styles.signinButton}>
          ログイン
        </button>
      </form>
      <div className={styles.links}>
        <p>
          ログインパスワードがわからない場合は
          <a href="https://qt-net.itcbb.net/forgot-password">こちら</a>
        </p>
        <p className={styles.notice}>
          ※ログインパスワードを3回間違えると、当日は利用できなくなりますのでご注意ください。
        </p>
        <p>
          アカウント名がわからない場合は
          <a href="https://qt-net.itcbb.net/forgot-login-account">こちら</a>
        </p>
      </div>
      <button
        className={styles.signupButton}
        onClick={() => navigate("https://qt-net.itcbb.net/signup")}
      >
        新規会員登録はこちら
      </button>
    </div>
  )
}

export default Signin
